
import * as customProviderRuntime$cTxXL2SOBR from '/var/builds/macopediapwafront/production/releases/132921/macopedia-shared/helpers/imgProxyProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 400,
    "sm": 768,
    "md": 1024,
    "lg": 1360,
    "xl": 1600,
    "xxl": null,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgProxy",
  "domains": [
    "macopedia.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imgProxy']: { provider: customProviderRuntime$cTxXL2SOBR, defaults: {"baseUrl":"https://macopedia.com/headless/imgproxy"} }
}
        